export const tableData = [
  {
    country: '🇦🇫 Afghanistan',
    emergency: '',
    police: 119,
    ambulance: '119, 102',
    fire: '112, 119',
    group: 'Asia',
    callingCodes: 93,
    info: 'You can dial 020 112 from mobile but only in Kabul.',
  },
  {
    country: '🇦🇱 Albania',
    emergency: '',
    police: 129,
    ambulance: 127,
    fire: 128,
    group: 'Europe',
    callingCodes: 355,
    info: '',
  },
  {
    country: '🇩🇿 Algeria',
    emergency: '',
    police: 17,
    ambulance: 14,
    fire: 14,
    group: 'Africa',
    callingCodes: 213,
    info: 'Dial 1548 for tourist police.',
  },
  {
    country: '🇦🇸 American Samoa',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: '1 684',
    info: '',
  },
  {
    country: '🇦🇩 Andorra',
    emergency: 112,
    police: 110,
    ambulance: 118,
    fire: 118,
    group: 'Europe',
    callingCodes: 376,
    info: '',
  },
  {
    country: '🇦🇴 Angola',
    emergency: '',
    police: 113,
    ambulance: '112 or 116',
    fire: 115,
    group: 'Africa',
    callingCodes: 244,
    info: '',
  },
  {
    country: '🇦🇮 Anguilla',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, Caribbean',
    callingCodes: '1 264',
    info:
      'Local Anguilla emergency numbers:<br /> <ul><li>Crime victims: +(264) 497 2333</li><li>Local Hospital: +(264) 497 2551.</li></ul>',
  },
  {
    country: '🇦🇬 Antigua & Barbuda',
    emergency: '911 or 999 or 777',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Caribbean, North America',
    callingCodes: '1 268',
    info:
      'Local Antigua & Barbuda emergency numbers:<br /> <ul><li>Paramedic Ambulance: +1 (268) 462-0251</li><li>Coast Guard: +1 (268) 462-0671.</li></ul>',
  },
  {
    country: '🇦🇷 Argentina',
    emergency: 911,
    police: 101,
    ambulance: 107,
    fire: 100,
    group: 'South America',
    callingCodes: 54,
    info:
      'Calls to 112 from mobile phones are redirected to the 911 number. Local Argentina emergency numbers:<br /> <ul><li>Tourist police: 0800-999-5000 / 0800-999-2838 (English is spoken).</li></ul>',
  },
  {
    country: '🇦🇲 Armenia',
    emergency: '911 or 112',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Europe, Asia',
    callingCodes: 374,
    info: '112 and 911 numbers are worldwide recognized emergency numbers.',
  },
  {
    country: '🇦🇼 Aruba',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Caribbean, North America',
    callingCodes: 297,
    info: '',
  },
  {
    country: '🇸🇭 Ascension Island',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'South Atlantic Ocean',
    callingCodes: 247,
    info:
      'Ascension Island is part of the part of the British Overseas Territory\n of Saint Helena, Ascension and Tristan da Cunha.',
  },
  {
    country: '🇦🇺 Australia',
    emergency: '000',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Australia, Oceania',
    callingCodes: 61,
    info:
      'Known as “Triple Zero”. The operator asks “Police, Fire, Ambulance?”. Secondary Australia emergency numbers:<br /> <ul><li>112 works from most mobiles</li><li>132 500 for State Emergency Service (SES).</li></ul>',
  },
  {
    country: '🇦🇹 Austria',
    emergency: 112,
    police: '112, 133',
    ambulance: 144,
    fire: 122,
    group: 'EU, Europe',
    callingCodes: 43,
    info:
      'Useful Austrian local emergency numbers:<br /> <ul><li>140 Mountain rescue</li><li>159 133 Non-emergency police.</li></ul>',
  },
  {
    country: '🇦🇿 Azerbaijan',
    emergency: '',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia, Europe',
    callingCodes: 94,
    info: '',
  },
  {
    country: '🇧🇸 Bahamas, The',
    emergency: '911 or 919\n 112 mobiles',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Caribbean, North America',
    callingCodes: '1 242',
    info: '',
  },
  {
    country: '🇧🇭 Bahrain',
    emergency: '999 or 112 mobiles',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 973,
    info: 'Dial 199 for traffic police, 994 for coast guard.',
  },
  {
    country: '🇧🇩 Bangladesh',
    emergency: 999,
    police: 999,
    ambulance: 999,
    fire: 999,
    group: 'Asia',
    callingCodes: 880,
    info: 'Emergency numbers only work in Dhaka and Chittagong.',
  },
  {
    country: '🇧🇧 Barbados',
    emergency: '',
    police: 211,
    ambulance: 511,
    fire: 311,
    group: 'Caribbean, North America',
    callingCodes: '1 246',
    info: '',
  },
  {
    country: '🇧🇾 Belarus',
    emergency: '',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: '',
    callingCodes: 375,
    info: '112 is redirected to 101 on mobiles.',
  },
  {
    country: '🇧🇪 Belgium',
    emergency: 112,
    police: 101,
    ambulance: 100,
    fire: 100,
    group: 'EU, Europe',
    callingCodes: 32,
    info:
      'The 101 emergency number is used in over a dozen countries worldwide,\n most commonly to reach the Police.',
  },
  {
    country: '🇧🇿 Belize',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 501,
    info: '',
  },
  {
    country: '🇧🇯 Benin',
    emergency: '',
    police: 117,
    ambulance: 112,
    fire: 118,
    group: 'Africa',
    callingCodes: 229,
    info: '',
  },
  {
    country: '🇧🇲 Bermuda',
    emergency: 911,
    police: 911,
    ambulance: 911,
    fire: 911,
    group: 'British, North America',
    callingCodes: '1 441',
    info: '',
  },
  {
    country: '🇧🇹 Bhutan',
    emergency: '',
    police: 113,
    ambulance: 112,
    fire: 110,
    group: 'Asia',
    callingCodes: 975,
    info: '',
  },
  {
    country: '🇧🇴 Bolivia',
    emergency: '',
    police: 110,
    ambulance: 118,
    fire: 119,
    group: 'South America',
    callingCodes: 591,
    info: 'Emergency calls to the 911 number from mobile phones are redirected to\n 110 (Police).',
  },
  {
    country: '🇧🇦 Bosnia & Herzegovina',
    emergency: '',
    police: 122,
    ambulance: 124,
    fire: 123,
    group: 'Europe',
    callingCodes: 387,
    info: 'Dial 112, 911 number or 08 for info about local emergency numbers.',
  },
  {
    country: '🇧🇼 Botswana',
    emergency: '997 or 911',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 267,
    info: '',
  },
  {
    country: '🇧🇷 Brazil',
    emergency: '',
    police: 190,
    ambulance: 192,
    fire: 193,
    group: 'South America',
    callingCodes: 55,
    info: 'Calls to 112 from mobile phones are redirected to 190 (Police).',
  },
  {
    country: '🇮🇴 British Indian Ocean Territory',
    emergency: '',
    police: '',
    ambulance: '',
    fire: '',
    group: 'British',
    callingCodes: 246,
    info: '',
  },
  {
    country: '🇻🇬 British Virgin Islands',
    emergency: '999 or 911',
    police: '',
    ambulance: 999,
    fire: 999,
    group: 'British, Caribbean',
    callingCodes: '1 284',
    info: '',
  },
  {
    country: '🇧🇳 Brunei',
    emergency: '',
    police: 993,
    ambulance: 991,
    fire: 995,
    group: 'Asia',
    callingCodes: 673,
    info: '',
  },
  {
    country: '🇧🇬 Bulgaria',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 359,
    info: '',
  },
  {
    country: '🇧🇫 Burkina Faso',
    emergency: '',
    police: 17,
    ambulance: 112,
    fire: 18,
    group: 'Africa',
    callingCodes: 226,
    info: '',
  },
  {
    country: '🇧🇮 Burundi',
    emergency: '',
    police: 117,
    ambulance: 112,
    fire: 118,
    group: 'Africa',
    callingCodes: 257,
    info: '',
  },
  {
    country: '🇰🇭 Cambodia',
    emergency: '',
    police: 117,
    ambulance: 119,
    fire: 118,
    group: 'Asia',
    callingCodes: 855,
    info: '',
  },
  {
    country: '🇨🇲 Cameroon',
    emergency: '',
    police: 17,
    ambulance: '',
    fire: 18,
    group: 'Africa',
    callingCodes: 237,
    info: '',
  },
  {
    country: '🇨🇦 Canada',
    emergency: 911,
    police: 911,
    ambulance: 911,
    fire: 911,
    group: 'North America',
    callingCodes: 1,
    info:
      'The 911 number is the main Canada emergency number. Calls to 112 from\n mobile phones may be redirected to the 911 number.',
  },
  {
    country: '🇨🇻 Cape Verde / Cabo Verde',
    emergency: '',
    police: 132,
    ambulance: 130,
    fire: 131,
    group: 'Africa',
    callingCodes: 238,
    info: '',
  },
  {
    country: '🇰🇾 Cayman Islands',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, Caribbean, North America',
    callingCodes: '1 345',
    info: '',
  },
  {
    country: '🇨🇫 Central African Republic',
    emergency: '',
    police: 117,
    ambulance: 1220,
    fire: 118,
    group: 'Africa',
    callingCodes: 236,
    info: '',
  },
  {
    country: '🇹🇩 Chad',
    emergency: '',
    police: 17,
    ambulance: '2251-4242',
    fire: 18,
    group: 'Africa',
    callingCodes: 235,
    info: '',
  },
  {
    country: '🇨🇱 Chile',
    emergency: '',
    police: 133,
    ambulance: 131,
    fire: 132,
    group: 'South America',
    callingCodes: 56,
    info: '',
  },
  {
    country: '🇨🇳 China',
    emergency: '',
    police: 110,
    ambulance: 120,
    fire: 119,
    group: 'Asia',
    callingCodes: 86,
    info: 'The 112 number on mobiles lists other emergency numbers.',
  },
  {
    country: '🇨🇽 Christmas Island',
    emergency: '000',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: '61 891',
    info: '',
  },
  {
    country: '🇨🇨 Cocos (Keeling) Islands',
    emergency: '000',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: '61 891',
    info: '',
  },
  {
    country: '🇨🇴 Colombia',
    emergency: '112 or 123',
    police: 156,
    ambulance: 132,
    fire: 119,
    group: 'South America',
    callingCodes: 57,
    info: '',
  },
  {
    country: '🇰🇲 Comoros',
    emergency: '',
    police: 17,
    ambulance: '772-03-73',
    fire: 18,
    group: 'Africa',
    callingCodes: 269,
    info: '',
  },
  {
    country: '🇨🇩 DR Congo / Democratic Republic of the Congo',
    emergency: '',
    police: '',
    ambulance: '',
    fire: 118,
    group: 'Africa',
    callingCodes: 243,
    info: '',
  },
  {
    country: '🇨🇬 Congo / Congo Republic',
    emergency: '',
    police: 112,
    ambulance: '',
    fire: 118,
    group: 'Africa',
    callingCodes: 242,
    info: '',
  },
  {
    country: '🇨🇰 Cook Islands',
    emergency: '',
    police: 999,
    ambulance: 998,
    fire: 996,
    group: 'Oceania',
    callingCodes: 682,
    info: '',
  },
  {
    country: '🇨🇷 Costa Rica',
    emergency: '112 or 911',
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 506,
    info: '911 and 112 numbers are recognized worldwide as emergency numbers.',
  },
  {
    country: '🇨🇮 Côte d’Ivoire / Ivory Coast',
    emergency: '',
    police: 111,
    ambulance: 185,
    fire: 180,
    group: 'Africa',
    callingCodes: 225,
    info: '',
  },
  {
    country: '🇭🇷 Croatia',
    emergency: 112,
    police: 192,
    ambulance: 194,
    fire: 193,
    group: 'EU, Europe',
    callingCodes: 385,
    info: '“Hrvatska” in Croatian.',
  },
  {
    country: '🇨🇺 Cuba',
    emergency: '',
    police: 106,
    ambulance: 104,
    fire: 105,
    group: 'North America',
    callingCodes: 53,
    info: '',
  },
  {
    country: '🇨🇼 Curaçao / Curacao',
    emergency: '',
    police: 911,
    ambulance: 912,
    fire: 911,
    group: 'North America',
    callingCodes: 599,
    info: '',
  },
  {
    country: '🇨🇾 Cyprus',
    emergency: '112 or 199',
    police: '112,  199',
    ambulance: '112,  199',
    fire: '112,  199',
    group: 'EU, Europe',
    callingCodes: 357,
    info: '',
  },
  {
    country: '🇨🇾 Northern Cyprus',
    emergency: 112,
    police: 155,
    ambulance: 112,
    fire: 199,
    group: 'Europe',
    callingCodes: 357,
    info: 'Dial 158 for Coastguard.',
  },
  {
    country: '🇨🇿 Czechia / Czech Republic',
    emergency: 112,
    police: 158,
    ambulance: 155,
    fire: 150,
    group: 'EU, Europe',
    callingCodes: 420,
    info: '',
  },
  {
    country: '🇩🇰 Denmark',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 45,
    info: '',
  },
  {
    country: '🇩🇯 Djibouti',
    emergency: '',
    police: 17,
    ambulance: 351351,
    fire: 18,
    group: 'Africa',
    callingCodes: 253,
    info: '',
  },
  {
    country: '🇩🇲 Dominica',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 767',
    info: '',
  },
  {
    country: '🇩🇴 Dominican Republic',
    emergency: '112 or 911',
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 809 / 829 / 849',
    info: 'Emergency calls to 112 from mobile phones are redirected to the 911\n number.',
  },
  {
    country: '🇹🇱 East Timor / Timor-Leste',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 670,
    info: '',
  },
  {
    country: '🇪🇨 Ecuador',
    emergency: 911,
    police: 101,
    ambulance: 911,
    fire: 102,
    group: 'South America',
    callingCodes: 593,
    info: '',
  },
  {
    country: '🇪🇬 Egypt',
    emergency: '',
    police: 122,
    ambulance: 123,
    fire: 180,
    group: 'Africa',
    callingCodes: 20,
    info: 'Police emergency numbers for tourists is 126.',
  },
  {
    country: '🇸🇻 El Salvador',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 503,
    info: '',
  },
  {
    country: '🇬🇶 Equatorial Guinea',
    emergency: '',
    police: 114,
    ambulance: 112,
    fire: 155,
    group: 'Africa',
    callingCodes: 240,
    info: '',
  },
  {
    country: '🇪🇷 Eritrea',
    emergency: '',
    police: 113,
    ambulance: 114,
    fire: 116,
    group: 'Africa',
    callingCodes: 291,
    info: '',
  },
  {
    country: '🇪🇪 Estonia',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 372,
    info: '',
  },
  {
    country: '🇸🇿 Eswatini (Swaziland)',
    emergency: '',
    police: 999,
    ambulance: 977,
    fire: 933,
    group: 'Africa',
    callingCodes: 268,
    info: '',
  },
  {
    country: '🇪🇹 Ethiopia',
    emergency: 911,
    police: 991,
    ambulance: 907,
    fire: 939,
    group: 'Africa',
    callingCodes: 251,
    info: '',
  },
  {
    country: '🇫🇰 Falkland Islands',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, South America',
    callingCodes: 500,
    info: 'Dial emergency number and ask for “Fire”, “Police” or “Ambulance”.',
  },
  {
    country: '🇫🇴 Faroe Islands',
    emergency: 112,
    police: '+298 351448',
    ambulance: 1870,
    fire: '+298 314544',
    group: 'EU, Europe',
    callingCodes: 298,
    info: 'The Faroe Islands are part of the Kingdom of Denmark with local\n emergency numbers.',
  },
  {
    country: '🇫🇯 Fiji',
    emergency: '',
    police: 911,
    ambulance: 911,
    fire: 9170,
    group: 'Oceania',
    callingCodes: 679,
    info: '',
  },
  {
    country: '🇫🇮 Finland',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 358,
    info: '',
  },
  {
    country: '🇫🇷 France',
    emergency: 112,
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'EU, Europe',
    callingCodes: 33,
    info: 'Dial 114 emergency number to send a fax or send SMS text.',
  },
  {
    country: '🇬🇫 French Guiana',
    emergency: 112,
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, South America',
    callingCodes: 594,
    info: '',
  },
  {
    country: '🇵🇫 French Polynesia',
    emergency: 112,
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, Oceania',
    callingCodes: 689,
    info: '',
  },
  {
    country: '🇬🇦 Gabon',
    emergency: '',
    police: 1730,
    ambulance: 1300,
    fire: 18,
    group: 'Africa',
    callingCodes: 241,
    info: '',
  },
  {
    country: '🇬🇲 Gambia',
    emergency: '',
    police: '117 or 112',
    ambulance: 116,
    fire: 118,
    group: 'Africa',
    callingCodes: 220,
    info: '',
  },
  {
    country: '🇬🇪 Georgia',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia, Europe',
    callingCodes: 995,
    info: '',
  },
  {
    country: '🇩🇪 Germany',
    emergency: 112,
    police: 110,
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 49,
    info:
      'The 110 emergency number is another popular country code which works in\n many other countries.',
  },
  {
    country: '🇬🇭 Ghana',
    emergency: 999,
    police: 191,
    ambulance: 193,
    fire: 192,
    group: 'Africa',
    callingCodes: 233,
    info: '',
  },
  {
    country: '🇬🇮 Gibraltar',
    emergency: '199 or 112 or 999',
    police: '199, 112, 999',
    ambulance: '199, 112, 999',
    fire: '199, 112, 999',
    group: 'British, Europe',
    callingCodes: 350,
    info: 'Emergency number for local hospital is +350 200 79700.',
  },
  {
    country: '🇬🇷 Greece',
    emergency: 112,
    police: 100,
    ambulance: 166,
    fire: 199,
    group: 'EU, Europe',
    callingCodes: 30,
    info: 'Dial 171 for tourist police, dial 108 for coastguard.',
  },
  {
    country: '🇬🇱 Greenland',
    emergency: '112 mobiles only',
    police: '112 mobiles only',
    ambulance: '112 mobiles only',
    fire: '112 mobiles only',
    group: 'EU, Europe',
    callingCodes: 299,
    info:
      'Landlines must call local emergency services. Greenland is part of the\n Kingdom of Denmark with local emergency numbers.',
  },
  {
    country: '🇬🇩 Grenada',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 473',
    info: '',
  },
  {
    country: '🇬🇵 Guadeloupe',
    emergency: '',
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, Americas, North America',
    callingCodes: 590,
    info: '',
  },
  {
    country: '🇬🇺 Guam',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: '1 671',
    info: '',
  },
  {
    country: '🇬🇹 Guatemala',
    emergency: '',
    police: '110, 120',
    ambulance: '122, 123 or 1554',
    fire: '122, 123 or 1554',
    group: 'North America',
    callingCodes: 502,
    info: '',
  },
  {
    country: '🇬🇳 Guinea',
    emergency: '',
    police: 122,
    ambulance: '442-020',
    fire: 1717,
    group: 'Africa',
    callingCodes: 224,
    info: '',
  },
  {
    country: '🇬🇼 Guinea-Bissau',
    emergency: 112,
    police: '112 or 117',
    ambulance: '112 or 119',
    fire: '112 or 118',
    group: 'Africa',
    callingCodes: 245,
    info: '',
  },
  {
    country: '🇬🇾 Guyana',
    emergency: 999,
    police: 911,
    ambulance: 913,
    fire: 912,
    group: 'South America',
    callingCodes: 592,
    info: '',
  },
  {
    country: '🇭🇹 Haiti',
    emergency: 118,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 509,
    info: '',
  },
  {
    country: '🇭🇳 Honduras',
    emergency: 911,
    police: 911,
    ambulance: 195,
    fire: 198,
    group: 'North America',
    callingCodes: 504,
    info: '',
  },
  {
    country: '🇭🇰 Hong Kong',
    emergency: '999 on landlines\n 112 on mobiles',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 852,
    info: 'Hong Kong is now a SAR (special administrative region) of China.',
  },
  {
    country: '🇭🇺 Hungary',
    emergency: 112,
    police: 107,
    ambulance: 104,
    fire: 105,
    group: 'EU, Europe',
    callingCodes: 36,
    info: '911 emergency calls are redirected to 112 on mobiles.',
  },
  {
    country: '🇮🇸 Iceland',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Europe',
    callingCodes: 354,
    info: '',
  },
  {
    country: '🇮🇳 India',
    emergency: 112,
    police: '112, 100',
    ambulance: '112, 108',
    fire: '112,101',
    group: 'Asia',
    callingCodes: 91,
    info:
      'Other emergency numbers:<br /> <ul><li>Dial 1363 tourist helpline</li><li>Dial 102 for Emergency Disaster Management</li><li> Dial 197 for Directory Enquiry.</li></ul>',
  },
  {
    country: '🇮🇩 Indonesia',
    emergency: 112,
    police: '112, 110',
    ambulance: '112, 118 or 119',
    fire: '112, 113',
    group: 'Asia',
    callingCodes: 62,
    info: 'Dial 115 for search and rescue.',
  },
  {
    country: '🇮🇷 Iran',
    emergency: '112 on mobiles',
    police: 110,
    ambulance: 115,
    fire: 125,
    group: 'Asia',
    callingCodes: 98,
    info: '911 and 112 redirect to 110 on mobile phones.',
  },
  {
    country: '🇮🇶 Iraq',
    emergency: 122,
    police: 104,
    ambulance: '',
    fire: 115,
    group: 'Asia',
    callingCodes: 964,
    info: '',
  },
  {
    country: '🇮🇪 Ireland',
    emergency: '112 or 999',
    police: '112 or 999',
    ambulance: '112 or 999',
    fire: '112 or 999',
    group: 'EU, Europe',
    callingCodes: 353,
    info:
      '112 and 999 numbers work in Ireland and Nothern Ireland and can also be\n used to reach the coastguard.',
  },
  {
    country: '🇮🇱 Israel',
    emergency: '112 on mobiles',
    police: 100,
    ambulance: 101,
    fire: 102,
    group: 'Asia',
    callingCodes: 972,
    info: '',
  },
  {
    country: '🇮🇹 Italy',
    emergency: 112,
    police: 113,
    ambulance: 118,
    fire: 115,
    group: 'EU, Europe',
    callingCodes: 39,
    info: '',
  },
  {
    country: '🇯🇲 Jamaica',
    emergency: '',
    police: 119,
    ambulance: 110,
    fire: 110,
    group: 'Caribbean, North America',
    callingCodes: '1 876',
    info: 'From Nov 2018 +1 658 will be an overlay of +1 876.',
  },
  {
    country: '🇯🇵 Japan',
    emergency: '',
    police: 110,
    ambulance: 119,
    fire: 119,
    group: 'Asia',
    callingCodes: 81,
    info: '911 and 112 redirect to 110 on mobile phones and US military landlines.',
  },
  {
    country: '🇯🇴 Jordan',
    emergency: 911,
    police: 911,
    ambulance: 911,
    fire: 911,
    group: 'Asia',
    callingCodes: 962,
    info: '112 on mobile phones.',
  },
  {
    country: '🇰🇿 Kazakhstan',
    emergency: 112,
    police: '102, 02',
    ambulance: '103, 03',
    fire: '101, 01',
    group: 'Asia',
    callingCodes: '7-6xx <br /> +7-7xx',
    info:
      'Kazakhstan telephone numbers share the <a target="_blank" href="https://en.wikipedia.org/wiki/Telephone_numbers_in_Russia">Russian unified numbering plan.</a>',
  },
  {
    country: '🇰🇪 Kenya',
    emergency: '999, 999 or 911',
    police: '112, 999 or 911',
    ambulance: '999, 999 or 911',
    fire: '999, 999 or 911',
    group: 'Africa',
    callingCodes: 254,
    info:
      '<ul><li>Local Fire brigade emergency numbers: 020-2222181 or 020-222218</li><li>St.Johns Ambulance: 020 221 0000</li><li>Kenya Maritime Authority: 110.</li></ul>',
  },
  {
    country: '🇰🇮 Kiribati',
    emergency: '',
    police: 992,
    ambulance: 994,
    fire: 993,
    group: 'Oceania',
    callingCodes: 686,
    info: '',
  },
  {
    country: '🇰🇵 North Korea',
    emergency: '112 or 119',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 850,
    info: '',
  },
  {
    country: '🇰🇷 South Korea',
    emergency: '',
    police: 112,
    ambulance: 119,
    fire: 119,
    group: 'Asia',
    callingCodes: 82,
    info: '',
  },
  {
    country: '🇽🇰 Kosovo',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 383,
    info: '',
  },
  {
    country: '🇰🇼 Kuwait',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 965,
    info: '',
  },
  {
    country: '🇰🇬 Kyrgyzstan',
    emergency: '',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia',
    callingCodes: 996,
    info: '',
  },
  {
    country: '🇱🇦 Laos / Lao',
    emergency: '',
    police: 191,
    ambulance: 195,
    fire: 190,
    group: 'Asia',
    callingCodes: 856,
    info: '',
  },
  {
    country: '🇱🇻 Latvia',
    emergency: 112,
    police: 2,
    ambulance: 3,
    fire: 1,
    group: 'EU, Europe',
    callingCodes: 371,
    info: '',
  },
  {
    country: '🇱🇧 Lebanon',
    emergency: '',
    police: '112 or 999',
    ambulance: 140,
    fire: 175,
    group: 'Asia',
    callingCodes: 961,
    info: '',
  },
  {
    country: '🇱🇸 Lesotho',
    emergency: '',
    police: 123,
    ambulance: 121,
    fire: 122,
    group: 'Africa',
    callingCodes: 266,
    info: '',
  },
  {
    country: '🇱🇷 Liberia',
    emergency: 911,
    police: 911,
    ambulance: 911,
    fire: 911,
    group: 'Africa',
    callingCodes: 231,
    info: '',
  },
  {
    country: '🇱🇾 Libya',
    emergency: 1515,
    police: '',
    ambulance: '',
    fire: 193,
    group: 'Africa',
    callingCodes: 218,
    info: '',
  },
  {
    country: '🇱🇮 Liechtenstein',
    emergency: 112,
    police: 117,
    ambulance: 144,
    fire: 118,
    group: 'Europe',
    callingCodes: 423,
    info: '',
  },
  {
    country: '🇱🇹 Lithuania',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 370,
    info: '',
  },
  {
    country: '🇱🇺 Luxembourg',
    emergency: 112,
    police: 113,
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 352,
    info: '',
  },
  {
    country: '🇲🇴 Macao',
    emergency: '999\n 110 or 112 on mobiles',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 853,
    info: '',
  },
  {
    country: '🇲🇬 Madagascar',
    emergency: '',
    police: 117,
    ambulance: 124,
    fire: 118,
    group: 'Africa',
    callingCodes: 261,
    info: '',
  },
  {
    country: '🇲🇼 Malawi',
    emergency: '',
    police: '997 or 990',
    ambulance: 998,
    fire: 999,
    group: 'Africa',
    callingCodes: 265,
    info: '',
  },
  {
    country: '🇲🇾 Malaysia',
    emergency: '112 or 999',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 60,
    info: '',
  },
  {
    country: '🇲🇻 Maldives',
    emergency: 102,
    police: 119,
    ambulance: 102,
    fire: 118,
    group: 'Asia',
    callingCodes: 960,
    info: '',
  },
  {
    country: '🇲🇱 Mali',
    emergency: '',
    police: 17,
    ambulance: '15, 112',
    fire: '18, 112',
    group: 'Africa',
    callingCodes: 223,
    info: '',
  },
  {
    country: '🇲🇹 Malta',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 356,
    info: '',
  },
  {
    country: '🇲🇭 Marshall Islands',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'COFA, Oceania',
    callingCodes: 692,
    info: '',
  },
  {
    country: '🇲🇶 Martinique',
    emergency: '',
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, Americas, North America',
    callingCodes: 596,
    info: '',
  },
  {
    country: '🇲🇷 Mauritania',
    emergency: '',
    police: 117,
    ambulance: 101,
    fire: 118,
    group: 'Africa',
    callingCodes: 222,
    info: '',
  },
  {
    country: '🇲🇺 Mauritius',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 230,
    info: '',
  },
  {
    country: '🇾🇹 Mayotte',
    emergency: '',
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, Africa',
    callingCodes: 262,
    info: '',
  },
  {
    country: '🇲🇽 Mexico',
    emergency: '911 or 066',
    police: 66,
    ambulance: 65,
    fire: 68,
    group: 'North America',
    callingCodes: 52,
    info: '',
  },
  {
    country: '🇫🇲 Micronesia, Federated States of',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'COFA, Oceania',
    callingCodes: 691,
    info: '',
  },
  {
    country: '🇲🇩 Moldova',
    emergency: 112,
    police: 122,
    ambulance: 124,
    fire: 123,
    group: 'Europe',
    callingCodes: 373,
    info: '',
  },
  {
    country: '🇲🇨 Monaco',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Europe',
    callingCodes: 377,
    info: '',
  },
  {
    country: '🇲🇳 Mongolia',
    emergency: 100,
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia',
    callingCodes: 976,
    info: 'Dial 100 to connect to any emergency service.',
  },
  {
    country: '🇲🇪 Montenegro',
    emergency: 112,
    police: 102,
    ambulance: 124,
    fire: 123,
    group: 'EU, Europe',
    callingCodes: 382,
    info: '',
  },
  {
    country: '🇲🇸 Montserrat',
    emergency: '',
    police: 999,
    ambulance: 911,
    fire: 911,
    group: 'British, Caribbean',
    callingCodes: '1 664',
    info:
      '<ul><li>Police local emergency numbers: +1 (664) 491 2555/2355/2556/4255</li><li>Local Fire, Search and Rescue: +1 (664) 491 7790.</li></ul>',
  },
  {
    country: '🇲🇦 Morocco',
    emergency: '112 on mobiles',
    police: 19,
    ambulance: 15,
    fire: 15,
    group: 'Africa',
    callingCodes: 212,
    info: '',
  },
  {
    country: '🇲🇿 Mozambique',
    emergency: '',
    police: 119,
    ambulance: 117,
    fire: 198,
    group: 'Africa',
    callingCodes: 258,
    info: '',
  },
  {
    country: '🇲🇲 Myanmar (Burma)',
    emergency: 999,
    police: 199,
    ambulance: 192,
    fire: 191,
    group: 'Asia',
    callingCodes: 95,
    info: '',
  },
  {
    country: '🇳🇦 Namibia',
    emergency: 112,
    police: '10 111',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 264,
    info: '',
  },
  {
    country: '🇳🇷 Nauru',
    emergency: '',
    police: 110,
    ambulance: 111,
    fire: 112,
    group: 'Oceania',
    callingCodes: 674,
    info: '',
  },
  {
    country: '🇳🇵 Nepal',
    emergency: '112 on mobiles',
    police: '100 or 103',
    ambulance: 102,
    fire: 101,
    group: 'Asia',
    callingCodes: 977,
    info: '',
  },
  {
    country: '🇳🇱 Netherlands',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 31,
    info: '',
  },
  {
    country: '🇳🇨 New Caledonia',
    emergency: 112,
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'Oceania',
    callingCodes: 687,
    info: '',
  },
  {
    country: '🇳🇿 New Zealand',
    emergency: 111,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: 64,
    info: 'Emergency calls to 112 and 911 numbers from mobile phones are redirected\n to 111',
  },
  {
    country: '🇳🇮 Nicaragua',
    emergency: 118,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 505,
    info: '',
  },
  {
    country: '🇳🇪 Niger',
    emergency: '',
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'Africa',
    callingCodes: 227,
    info: '',
  },
  {
    country: '🇳🇬 Nigeria',
    emergency: 112,
    police: 112,
    ambulance: 112,
    fire: 112,
    group: 'Africa',
    callingCodes: 234,
    info: '',
  },
  {
    country: '🇳🇺 Niue',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, Oceania',
    callingCodes: 683,
    info: '',
  },
  {
    country: '🇳🇫 Norfolk Island',
    emergency: 999,
    police: 977,
    ambulance: 911,
    fire: 955,
    group: 'Australian, Oceania',
    callingCodes: 672,
    info: '',
  },
  {
    country: '🇲🇰 North Macedonia',
    emergency: 112,
    police: 194,
    ambulance: 192,
    fire: 193,
    group: 'Europe',
    callingCodes: 389,
    info: '',
  },
  {
    country: '🇲🇵 Northern Mariana Islands',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'USA, Oceania',
    callingCodes: '1 670',
    info:
      'Local emergency numbers:<br /> <ul><li>Medical: 670-664-9135 and 670-664-9136</li><li>Fire: 670-664-9140.</li></ul>',
  },
  {
    country: '🇳🇴 Norway',
    emergency: '',
    police: 112,
    ambulance: 113,
    fire: 110,
    group: 'Europe',
    callingCodes: 47,
    info: '',
  },
  {
    country: '🇴🇲 Oman',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 968,
    info: '',
  },
  {
    country: '🇵🇰 Pakistan',
    emergency: '15, 1122 landlines\n 112 mobiles',
    police: 15,
    ambulance: 115,
    fire: 16,
    group: 'Asia',
    callingCodes: 92,
    info: 'Dial 15 or 1122 to connect to any emergency service.',
  },
  {
    country: '🇵🇼 Palau',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'COFA, Oceania',
    callingCodes: 680,
    info: '',
  },
  {
    country: '🇵🇸 Palestinian Territories',
    emergency: '',
    police: '',
    ambulance: '',
    fire: '',
    group: '',
    callingCodes: 92,
    info: '',
  },
  {
    country: '🇵🇦 Panama',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 507,
    info: '',
  },
  {
    country: '🇵🇬 Papua New Guinea',
    emergency: 111,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: 675,
    info: '',
  },
  {
    country: '🇵🇾 Paraguay',
    emergency: 911,
    police: 912,
    ambulance: 141,
    fire: 132,
    group: 'South America',
    callingCodes: 595,
    info: '',
  },
  {
    country: '🇵🇪 Peru',
    emergency: 911,
    police: 105,
    ambulance: 105,
    fire: 106,
    group: 'South America',
    callingCodes: 51,
    info: '',
  },
  {
    country: '🇵🇭 Philippines',
    emergency: '117, 112 and 911',
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 63,
    info: 'Send emergency SMS text messages via 117.',
  },
  {
    country: '🇵🇳 Pitcairn Islands',
    emergency: '',
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, Oceania',
    callingCodes: 64,
    info: '',
  },
  {
    country: '🇵🇱 Poland',
    emergency: 112,
    police: 997,
    ambulance: 999,
    fire: 998,
    group: 'EU, Europe',
    callingCodes: 48,
    info: '',
  },
  {
    country: '🇵🇹 Portugal',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 351,
    info: '',
  },
  {
    country: '🇵🇷 Puerto Rico',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 787',
    info: '',
  },
  {
    country: '🇶🇦 Qatar',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 974,
    info: '',
  },
  {
    country: '🇷🇪 Réunion',
    emergency: 112,
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, Africa',
    callingCodes: 262,
    info: '',
  },
  {
    country: '🇷🇴 Romania',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 40,
    info: '',
  },
  {
    country: '🇷🇺 Russia',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia, Europe',
    callingCodes: 7,
    info:
      'Refer to the <a target="_blank" href="https://en.wikipedia.org/wiki/Telephone_numbers_in_Russia">Russian unified numbering plan</a> for full details.',
  },
  {
    country: '🇷🇼 Rwanda',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 250,
    info: '',
  },
  {
    country: '🇸🇭 Saint Helena',
    emergency: '',
    police: 999,
    ambulance: 911,
    fire: 999,
    group: 'British, Africa',
    callingCodes: 290,
    info: '',
  },
  {
    country: '🇰🇳 Saint Kitts & Nevis',
    emergency: '',
    police: 911,
    ambulance: 911,
    fire: 333,
    group: 'Caribbean, North America',
    callingCodes: '1 869',
    info: '',
  },
  {
    country: '🇱🇨 Saint Lucia',
    emergency: '911 or 999',
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 758',
    info: '',
  },
  {
    country: '🇲🇫 Saint Martin',
    emergency: '',
    police: 911,
    ambulance: 912,
    fire: 910,
    group: 'Caribbean.',
    callingCodes: 33,
    info: '',
  },
  {
    country: '🇵🇲 Saint Pierre & Miquelon',
    emergency: '',
    police: 17,
    ambulance: 15,
    fire: 18,
    group: 'French, North America',
    callingCodes: 508,
    info: '',
  },
  {
    country: '🇻🇨 Saint Vincent & Grenadines',
    emergency: '999 or 911',
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: '1 784',
    info: '',
  },
  {
    country: '🇼🇸 Samoa',
    emergency: 999,
    police: 995,
    ambulance: 996,
    fire: 994,
    group: 'Oceania',
    callingCodes: 685,
    info: '',
  },
  {
    country: '🇸🇲 San Marino',
    emergency: '',
    police: 113,
    ambulance: 118,
    fire: 115,
    group: 'Europe',
    callingCodes: 378,
    info: 'If dialing from Italy dial +39 0549.',
  },
  {
    country: '🇸🇹 São Tomé & Príncipe',
    emergency: '',
    police: 222222,
    ambulance: '221221, 221222 & 221234',
    fire: 112,
    group: 'Africa',
    callingCodes: 239,
    info: 'Local hospital emergency numbers: +239 2-221-222.',
  },
  {
    country: '🇸🇦 Saudi Arabia',
    emergency: '',
    police: 999,
    ambulance: 997,
    fire: 998,
    group: 'Asia',
    callingCodes: 966,
    info: '',
  },
  {
    country: '🇸🇳 Senegal',
    emergency: '',
    police: 17,
    ambulance: '33 889 1515',
    fire: 18,
    group: 'Africa',
    callingCodes: 221,
    info: 'Dial 33-860-3810 for tourist police.',
  },
  {
    country: '🇷🇸 Serbia',
    emergency: 112,
    police: 192,
    ambulance: 194,
    fire: 193,
    group: 'Europe',
    callingCodes: 381,
    info: '',
  },
  {
    country: '🇸🇨 Seychelles',
    emergency: '112 or 999',
    police: 133,
    ambulance: 151,
    fire: '',
    group: 'Africa',
    callingCodes: 248,
    info: '',
  },
  {
    country: '🇸🇱 Sierra Leone',
    emergency: '',
    police: 999,
    ambulance: 999,
    fire: 19,
    group: 'Africa',
    callingCodes: 232,
    info: '',
  },
  {
    country: '🇸🇬 Singapore',
    emergency: '112 on foreign mobiles',
    police: 999,
    ambulance: 995,
    fire: 995,
    group: 'Asia',
    callingCodes: 65,
    info: '',
  },
  {
    country: '🇸🇰 Slovakia',
    emergency: 112,
    police: 158,
    ambulance: 155,
    fire: 150,
    group: 'EU, Europe',
    callingCodes: 421,
    info: '',
  },
  {
    country: '🇸🇮 Slovenia',
    emergency: 112,
    police: 113,
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 386,
    info: '',
  },
  {
    country: '🇸🇧 Solomon Islands',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa, Oceania',
    callingCodes: 677,
    info: '',
  },
  {
    country: '🇸🇴 Somalia',
    emergency: '',
    police: 888,
    ambulance: 999,
    fire: 555,
    group: 'Africa',
    callingCodes: 252,
    info: '',
  },
  {
    country: '🇿🇦 South Africa',
    emergency: '112 on mobiles',
    police: 10111,
    ambulance: 10177,
    fire: 10111,
    group: 'Africa',
    callingCodes: 27,
    info: 'In Cape Town the emergency number is 107.',
  },
  {
    country: '🇬🇸 South Georgia & South Sandwich Islands',
    emergency: 999,
    police: 999,
    ambulance: 999,
    fire: 999,
    group: 'British, Antartic',
    callingCodes: 500,
    info: '',
  },
  {
    country: '🇸🇸 South Sudan',
    emergency: 999,
    police: 999,
    ambulance: 999,
    fire: 999,
    group: 'Africa',
    callingCodes: 211,
    info: '',
  },
  {
    country: '🇪🇸 Spain',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 34,
    info: '',
  },
  {
    country: '🇱🇰 Sri Lanka',
    emergency: '',
    police: '119, 118',
    ambulance: 110,
    fire: 111,
    group: 'Asia',
    callingCodes: 94,
    info: '',
  },
  {
    country: '🇸🇩 Sudan',
    emergency: 999,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 249,
    info: '',
  },
  {
    country: '🇸🇷 Suriname',
    emergency: 115,
    police: 115,
    ambulance: '115, 113',
    fire: '115, 110',
    group: 'South America',
    callingCodes: 597,
    info: '',
  },
  {
    country: '🇸🇪 Sweden',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'EU, Europe',
    callingCodes: 46,
    info: '',
  },
  {
    country: '🇨🇭 Switzerland',
    emergency: 112,
    police: 117,
    ambulance: 144,
    fire: 118,
    group: 'Europe',
    callingCodes: 41,
    info: '',
  },
  {
    country: '🇸🇾 Syria',
    emergency: '',
    police: 112,
    ambulance: 110,
    fire: 113,
    group: 'Asia',
    callingCodes: 963,
    info: '',
  },
  {
    country: '🇹🇼 Taiwan, Republic of China',
    emergency: '112 on mobiles',
    police: 110,
    ambulance: 119,
    fire: 119,
    group: 'Asia',
    callingCodes: 886,
    info: '',
  },
  {
    country: '🇹🇯 Tajikistan',
    emergency: '112 on mobiles',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia',
    callingCodes: 992,
    info: '',
  },
  {
    country: '🇹🇿 Tanzania',
    emergency: 112,
    police: 112,
    ambulance: 114,
    fire: 115,
    group: 'Africa',
    callingCodes: 255,
    info: '',
  },
  {
    country: '🇹🇭 Thailand',
    emergency: '911 or 191',
    police: 191,
    ambulance: 1669,
    fire: 199,
    group: 'Asia',
    callingCodes: 66,
    info: 'Dial 1155 for Tourist Police.',
  },
  {
    country: '🇹🇬 Togo',
    emergency: '',
    police: '117 or 161',
    ambulance: 8200,
    fire: 118,
    group: 'Africa',
    callingCodes: 228,
    info: '',
  },
  {
    country: '🇹🇰 Tokelau',
    emergency: '',
    police: '',
    ambulance: '',
    fire: '',
    group: 'New Zealand, Oceania',
    callingCodes: 690,
    info: '',
  },
  {
    country: '🇹🇴 Tonga',
    emergency: 911,
    police: 922,
    ambulance: 933,
    fire: 999,
    group: 'Oceania',
    callingCodes: 676,
    info: '',
  },
  {
    country: '🇹🇹 Trinidad & Tobago',
    emergency: '',
    police: '999 or 911',
    ambulance: 811,
    fire: 990,
    group: 'Caribbean, North America',
    callingCodes: '1 868',
    info: '',
  },
  {
    country: '🇹🇳 Tunisia',
    emergency: '',
    police: 197,
    ambulance: 190,
    fire: 198,
    group: 'Africa',
    callingCodes: 216,
    info: '',
  },
  {
    country: '🇹🇷 Turkey',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Asia',
    callingCodes: 90,
    info: '',
  },
  {
    country: '🇹🇲 Turkmenistan',
    emergency: 112,
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia',
    callingCodes: 993,
    info: '',
  },
  {
    country: '🇹🇨 Turks & Caicos Islands',
    emergency: '911 or 999',
    police: '',
    ambulance: '',
    fire: '',
    group: 'British, Caribbean, North America',
    callingCodes: '1 649',
    info: '',
  },
  {
    country: '🇹🇻 Tuvalu',
    emergency: '',
    police: 911,
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: 688,
    info: '',
  },
  {
    country: '🇺🇬 Uganda',
    emergency: '999, 112 mobiles',
    police: '999, 112',
    ambulance: '999, 112',
    fire: '999, 112',
    group: 'Africa',
    callingCodes: 256,
    info: '',
  },
  {
    country: '🇺🇦 Ukraine',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Europe',
    callingCodes: 380,
    info: '',
  },
  {
    country: '🇦🇪 United Arab Emirates',
    emergency: '',
    police: '999, 112',
    ambulance: '999, 998',
    fire: 997,
    group: 'Asia',
    callingCodes: 971,
    info: '',
  },
  {
    country: '🇬🇧 United Kingdom (UK; England, Scotland, Wales, Northern\n Island)',
    emergency: '112 0r 999',
    police: '999 or 112',
    ambulance: 999,
    fire: 999,
    group: 'EU, Europe',
    callingCodes: 44,
    info:
      'Dial emergency number and ask for “Fire”, “Police” or “Ambulance”.\n Pre-register to send SMS text messages to 999.',
  },
  {
    country: '🇺🇸 United States of America',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'North America',
    callingCodes: 1,
    info:
      'Emergency calls to 112 will sometimes, but not always, redirect to the\n 911 number. The American police number is accessed via the 911 number.',
  },
  {
    country: '🇺🇾 Uruguay',
    emergency: 911,
    police: 109,
    ambulance: 105,
    fire: 104,
    group: 'South America',
    callingCodes: 598,
    info: '',
  },
  {
    country: '🇺🇿 Uzbekistan',
    emergency: '',
    police: 102,
    ambulance: 103,
    fire: 101,
    group: 'Asia',
    callingCodes: 998,
    info: '',
  },
  {
    country: '🇻🇺 Vanuatu',
    emergency: 112,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Oceania',
    callingCodes: 678,
    info: '',
  },
  {
    country: '🇻🇦 Vatican City / Holy See',
    emergency: '',
    police: 113,
    ambulance: 118,
    fire: 115,
    group: 'Europe',
    callingCodes: '39, +379',
    info: 'Emergency calls to 111 from mobiles are redirected to 113 (Police).',
  },
  {
    country: '🇻🇪 Venezuela',
    emergency: '911, 171',
    police: '911, 171',
    ambulance: '911, 171',
    fire: '911, 171',
    group: 'South America',
    callingCodes: 58,
    info: '',
  },
  {
    country: '🇻🇳 Vietnam',
    emergency: '',
    police: 113,
    ambulance: 115,
    fire: 114,
    group: 'Asia',
    callingCodes: 84,
    info: '',
  },
  {
    country: '🇻🇮 United States Virgin Islands',
    emergency: 911,
    police: '',
    ambulance: '',
    fire: '',
    group: 'US.',
    callingCodes: '1 340',
    info: 'Local hospital emergency numbers: Roy L. Schneider Hospital: 776.8311',
  },
  {
    country: '🇼🇫 Wallis & Futuna',
    emergency: '',
    police: 18,
    ambulance: 15,
    fire: 17,
    group: 'French, Oceania',
    callingCodes: 681,
    info: '',
  },
  {
    country: '🇪🇭 Western Sahara',
    emergency: 150,
    police: '',
    ambulance: '',
    fire: '',
    group: 'Africa',
    callingCodes: 212,
    info: 'This disputed state is part of Morocco.',
  },
  {
    country: '🇾🇪 Yemen',
    emergency: '',
    police: 194,
    ambulance: 191,
    fire: 191,
    group: 'Asia',
    callingCodes: 967,
    info: '',
  },
  {
    country: '🇿🇲 Zambia',
    emergency: 112,
    police: 999,
    ambulance: 993,
    fire: 991,
    group: 'Africa',
    callingCodes: 260,
    info: '',
  },
  {
    country: '🇿🇼 Zimbabwe',
    emergency: 999,
    police: 995,
    ambulance: 994,
    fire: 993,
    group: 'Africa',
    callingCodes: 264,
    info: '',
  },
];
