import React from 'react';
import { tableData } from './tableData';
import css from './style.module.css';

const EmergenciesPageTable = ({}) => {
  return (
    <div className={css['table_wrapper']}>
      <div className={css['table']}>
        <div className={css['table-heading']}>Country / Territory</div>
        <div className={css['table-heading']}>☎ Emergency</div>
        <div className={css['table-heading']}>☎ Police</div>
        <div className={css['table-heading']}>☎ Ambulance</div>
        <div className={css['table-heading']}>☎ Fire</div>
        <div className={css['table-heading']}>Group</div>
        <div className={css['table-heading']}>Calling codes</div>
        <div className={css['table-heading']}>Local emergency numbers & info</div>

        {tableData?.map((row, index) => {
          const { country, emergency, police, ambulance, fire, group, callingCodes, info } = row;
          return (
            <React.Fragment key={index}>
              <div className={css['table-col']}>{country}</div>
              <div className={css['table-col']}>{emergency}</div>
              <div className={css['table-col']}>{police}</div>
              <div className={css['table-col']}>{ambulance}</div>
              <div className={css['table-col']}>{fire}</div>
              <div className={css['table-col']}>{group}</div>
              <div
                className={css['table-col']}
                dangerouslySetInnerHTML={{ __html: `+${callingCodes}` }}
              ></div>
              <div className={css['table-col']} dangerouslySetInnerHTML={{ __html: info }}></div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default EmergenciesPageTable;
